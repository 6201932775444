import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Hero from "../components/landing/hero"
import Mission from "../components/landing/mission"
import Stats from "../components/landing/stats"
import Contact from "../components/landing/contact"
import Social from "../components/landing/social"

// style
import "../styles/landing.css"

// utils
import { getRedirectLanguage } from "../utils/helperFunctions"

const IndexPage = () => {
  const [lang, setLang] = useState("en")
  useEffect(() => {
    const urlLang = getRedirectLanguage(setLang)
    navigate(`/${urlLang}`, { replace: true })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      headerImage: file(
        relativePath: { eq: "pasha-waltz-wjbu-6VntQc-unsplash.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      belowImage: file(
        relativePath: { eq: "austin-distel-wawEfYdpkag-unsplash.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      langQuery: site {
        siteMetadata {
          en {
            header {
              commissioning
              modeling
              retrofits
              cfd
              projects
              contact
            }
            footer {
              contact
              navItems
            }
            landing {
              contact {
                title
                title2
                subTitle
                subTitle2
                tag
                body
                address
                callTitle
                name
                email
                companyName
                industry
                textArea
                btn
              }
              hero {
                title
                headerText
                subText
              }
              mission {
                title
                text
              }
              data {
                body1
                body2
                stat1
                stat2
                stat3
                stat4
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata[`${lang}`]?.header}
      footerLang={data?.langQuery?.siteMetadata[`${lang}`]?.footer}
      langType="en"
    >
      <SEO
        title="Home"
        description="Energy Efficient Engineering delivers energy efficiency worldwide. We have expertise in mechanical engineering, specialized in Energy Modeling, Energy Retrofitting, and CFD Modeling."
      />
      <Hero
        fluid={data?.headerImage?.childImageSharp?.fluid}
        lang={data?.langQuery?.siteMetadata[`${lang}`].landing}
      />
      <Mission
        lang={data?.langQuery?.siteMetadata[`${lang}`].landing?.mission}
      />
      <Stats
        fluidBelow={data?.belowImage?.childImageSharp?.fluid}
        lang={data?.langQuery?.siteMetadata[`${lang}`].landing?.data}
      />
      <Contact
        lang={data?.langQuery?.siteMetadata[`${lang}`].landing?.contact}
      />
      <Social />
    </Layout>
  )
}

export default IndexPage
